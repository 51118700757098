html, body{
    font-family: 'Lato', sans-serif;
}

.app{
    background-color: grey;
}

.header{
    top: 0;
    position: absolute;
    z-index: 200;
    padding: 4rem 4rem 0px 4rem;
}

.bg-video video {
    object-fit: cover;
    height: 100vh!important;
}

.main-content{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    justify-content: space-around;
    position: absolute;
    z-index: 100;
    top: 0;
    background-color: rgba(255, 255, 255, 0.45);
    width: 100%;
    height: 100vh;
    transition: all 1s ease-in-out;
}

.secondary-content-l1{

}

.primary-logo{
    width: 60%;
    height: auto;
    transition: width 1s;
}

.primary-logo-small{
    width: 45%!important;
    height: auto;
}

.primary-logo-container{
    text-align: center;
    margin-bottom: 4rem;
}

.amenity-menu{
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    justify-content: space-around;
    max-height: 4000px;
    transition: all 1s ease-in-out;
    overflow: hidden;
    opacity: 1;
}

.amenity-menu-collapsed{
    max-height: 50px;
    opacity: 0;
}

.amenity-row:first-of-type{
    margin-top: 0;
}

.amenity-row{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 4rem;
}

.amenity-item{
    text-align: center;
    margin-left: 1rem;
    margin-right: 1rem;
    font-weight: bold;
    font-size: 1.2rem;
    color: #184273;
}


.amenity-item img{
    width: 12rem;
    height: auto;
}

.hamburger-container{
    background-color: rgba(22, 66, 116, 0.8);
    padding: 7px 10px 7px 10px;
    border-radius: 5px;
}

.nav-menu{
    position: absolute;
    top: 0;
    left: 0;
    background-color: #FFFFFF;
    z-index: 190;
    font-size: 2.2rem;
    padding-top: 8rem;
    padding-left: 4rem;
    padding-right: 4rem;
    height: 100vh;
    transition: all 0.5s ease-out;
    border-right: 1px solid #e9e9e9;
}

.collapsed{
    transform: translateX(-100%);
}

.nav-menu ul{
    list-style: none;
    padding: 0;
}

.nav-menu li{
    margin-top: 1rem;
    border-bottom: 1px solid #FFA400;
    padding-bottom: 1rem;
    cursor: pointer;
    transition: color 0.3s ease-in;
}

.nav-menu li:hover{
    color: #FFA400;
}

.secondary-logo-container{
    width: 100%;
    display: flex;
    justify-content: center;
}

.overlay-logo-container{
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0;
    z-index: 175;
    margin-top: 4rem;
    -webkit-filter: drop-shadow(0px 0px 1px rgba(255,255,255,0.5));
}

.secondary-logo{
    width: 30%;
    margin-bottom: 4rem;
}

.secondary-content-container{
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    justify-content: space-around;
    position: absolute;
    z-index: 150;
    top: 0;
    width: 100%;
    height: 100vh;
    transition: all 0.5s ease-in-out;
    opacity: 1;
}

.full-width{
    width: 100%;
}

.fifth-width{
    width: 20%;
    display: inline-block;
    position: relative;
}

.third-width{
    width: 33.33%;
    display: inline-block;
    position: relative;
}

.half-width{
    width: 50%;
    max-height: 50vh;
    display: inline-block;
    position: relative;
    overflow: hidden;
}

.velum-title{
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    font-size: 3rem;
    background-color: rgba(24, 66, 115, 0.5);
    color: #FFA400;
    padding: 1rem 0 1rem 0;
}

.secondary-content-container-hidden{
    opacity: 0;
}

.secondary-content-ln-hidden{
    opacity: 0;
    max-height: 0;
}

.full-screen{
    width: 100vw;
    height: 100vh;
    object-fit: cover;
}

.floating-button{
    position: absolute;
    z-index: 199;
    top: 0;
    right: 0;
    background-color: #FFA400;
    color: #FFFFFF;
    margin-top: 4rem;
    margin-right: 4rem;
    padding: 10px;
    border-radius: 5px;
    font-size: 2rem;
}

.full-screen-overlay{
    z-index: 300;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
}

.full-screen-overlay-hidden{
    display: none;
}

.close-full-screen-overlay{
    position: absolute;
    top: 2rem;
    right: 2rem;
    z-index: 350;
    font-weight: bold;
    font-size: 2rem;
    color: white;
    background-color: #FFA400;
    border-radius: 5px;
    padding: 5px 10px 5px 10px;
}

.community-bullets{
    width: 100vw;
    height: 100vh;
}